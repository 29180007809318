@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap");

.nav-container {
  box-sizing: border-box;
  max-width: 100%;
  background: url("https://i.imgur.com/sHbfHEu.jpg");
  font-family: "Archivo Black", sans-serif;
  font-style: normal;
  padding: 5px 20px;
  justify-content: space-between;
  flex-direction: column;
  color: #e4e2db;
  position: relative;

  .info-1 {
    gap: 60px;
    justify-content: space-around;
    border-bottom: 1px solid white;
    background: rgba(0, 0, 0, 0.5);

    div {
      color: white;
      padding: 10px 20px;
    }
  }

  .link,
  a:visited {
    text-decoration: none;
    color: #e0c37e;
  }

  .links-container {
    list-style: none;
    gap: 20px;
    justify-content: center;

    .link {
      text-transform: uppercase;
      font-size: 0.9rem;
      transition: all 0.2s ease-in-out;
      padding: 10px 15px;
      border-radius: 3px;
      border-bottom: 2px solid transparent;
      text-decoration: none;
      border: 2px solid transparent;

      .link-styles,
      a:visited {
        text-decoration: none;
        color: #e0c37e;
      }

      &:hover {
        border: 2px solid #e0c37e;
      }
    }
  }

  .social-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social-icon {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50px;
    margin-right: 20px;
    background: white;
    border: 1px solid transparent;
  }

  .main-container {
    flex-direction: column;

    img {
      position: relative;
      animation: float 3s ease-in-out infinite;
      width: 600px;
    }

    .title-name {
      color: #e4e2db;
      font-size: 3.4rem;
      text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000,
        2px 2px 0 #000;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-45px);
  }
  100% {
    transform: translateY(0);
  }
}
