@import url("https://fonts.googleapis.com/css2?family=Playwrite+MX:wght@100..400&display=swap");

.hero-container {
  box-sizing: border-box;
  border-bottom: 5px solid #a28d5b;
  width: 100%;

  img {
    width: 100%;
    object-fit: cover;
  }
}
