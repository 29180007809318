.footer-container {
  font-size: 0.8rem;
  background: #221f1e;
  color: #e0c37e;
  line-height: 30px;
  padding: 20px 0px;

  .info {
    width: 80%;
    margin: 0 auto;
  }
}
