@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.banner-container {
  font-family: "Montserrat", sans-serif;
  padding: 40px 0px;

  .address {
    font-size: 1.3rem;
    margin-bottom: 30px;
    cursor: pointer;
    color: #e0c37e;

    .copy {
      width: 30px;
    }
  }

  .info-2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    div {
      background: rgba(0, 0, 0, 0.7);
      border: 1px solid white;
      padding: 20px 10px;
      border-radius: 10px;
    }

    .red {
      color: rgb(244, 91, 91);
    }

    .green {
      color: rgb(164, 207, 94);
    }
  }
}
