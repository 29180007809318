.App {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  height: 100vh;
}

.center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
